'use client';

// Ideally this is in a css file but it would not load properly
import { useEffect } from 'react';
import { datadogRum } from '~/app/lib/datadog/rum-init';

const styles: Record<string, React.CSSProperties> = {
  error: {
    height: '100%',
    minHeight: '800px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0',
    padding: '0',
    backgroundColor: '#65A989',
    backgroundImage:
      'url(https://www.nerdwallet.com/cdn/img/background/error/ErrorPage_BananaPeel_green.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textAlign: 'center',
    alignItems: 'center',
  },
  h1: {
    color: 'white',
    margin: '0 auto',
    letterSpacing: '-0.67px',
    maxWidth: '350px',
    display: 'block',
  },
  button: {
    color: '#fff',
    background: '#6cc551',
    border: '1px solid transparent',
    padding: '7px 40px',
    height: '40px',
    borderRadius: '4px',
    letterSpacing: '-0.34px',
    textDecoration: 'none',
    cursor: 'pointer',
    maxWidth: '300px',
  },
};

const customerFacingErrorMessage = 'Sorry, looks like something slipped up.';

export default function ErrorComponent({
  error,
}: {
  error: Error & { digest?: string };
}): React.ReactElement {
  useEffect(() => {
    error.name = 'Banana';
    datadogRum.addError(error);
  }, []);

  return (
    <div style={styles.error}>
      <h1 style={styles.h1}>{customerFacingErrorMessage}</h1>
      <button
        type="button"
        style={styles.button}
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => {
            window.location.reload();
          }
        }
      >
        {`Let's try that again`}
      </button>
    </div>
  );
}
