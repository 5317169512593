'use client';

import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';

const DataDogRUM = () => {
  useEffect(() => {
    if (
      process.env.DATADOG_APPLICATION_ID &&
      process.env.DATADOG_CLIENT_TOKEN
    ) {
      datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: process.env.DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'front-page-next',
        env: process.env.DEPLOYABLE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        version: process.env.NEXT_PUBLIC_APP_VERSION,
        defaultPrivacyLevel: 'mask-user-input',
      });
    }
  }, []);

  return null;
};

export { datadogRum }; // Export the datadogRum instance
export default DataDogRUM;
